// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Cfmcqs from "../../blocks/cfmcqs/src/Cfmcqs";
import Customisableuserprofiles2 from "../../blocks/customisableuserprofiles2/src/Customisableuserprofiles2";
import Timetracker2 from "../../blocks/timetracker2/src/Timetracker2";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Cfadmissionbatchmanagement from "../../blocks/cfadmissionbatchmanagement/src/Cfadmissionbatchmanagement";
import Cfcoursecreationbytrainerteacher from "../../blocks/cfcoursecreationbytrainerteacher/src/Cfcoursecreationbytrainerteacher";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Customform from "../../blocks/customform/src/Customform";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Documentstorage from "../../blocks/documentstorage/src/Documentstorage";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import Contentflag from "../../blocks/contentflag/src/Contentflag";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Notifications from "../../blocks/notifications/src/Notifications";
import Cfmerchantcentreonboarding from "../../blocks/cfmerchantcentreonboarding/src/Cfmerchantcentreonboarding";
import Blogpostsmanagement from "../../blocks/blogpostsmanagement/src/Blogpostsmanagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Cfcertification from "../../blocks/cfcertification/src/Cfcertification";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Communityforum3 from "../../blocks/communityforum3/src/Communityforum3";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Addresses from "../../blocks/addressmanagement/src/Addresses";
import AddAddress from "../../blocks/addressmanagement/src/AddAddress";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Scoring from "../../blocks/scoring/src/Scoring";
import Videos from "../../blocks/videos/src/Videos";
import Cfvideoconferencingtoolintegration from "../../blocks/cfvideoconferencingtoolintegration/src/Cfvideoconferencingtoolintegration";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Chat from "../../blocks/chat/src/Chat";
import ChatView from "../../blocks/chat/src/ChatView";
import Videomanagement from "../../blocks/videomanagement/src/Videomanagement";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import PerformanceTracker from "../../blocks/performancetracker/src/PerformanceTracker";
import PerformanceTrackerCoursesScreen from "../../blocks/performancetracker/src/PerformanceTrackerCoursesScreen";
import PerformanceTrackerTestsForCourseScreen from "../../blocks/performancetracker/src/PerformanceTrackerTestsForCourseScreen";
import PerformanceTrackerStudentsOnCourseScreen from "../../blocks/performancetracker/src/PerformanceTrackerStudentsOnCourseScreen";
import PerformanceTrackerTestScoresForStudentScreen from "../../blocks/performancetracker/src/PerformanceTrackerTestScoresForStudentScreen";
import PerformanceTrackerBasicUserCoursesScreen from "../../blocks/performancetracker/src/PerformanceTrackerBasicUserCoursesScreen";
import PerformanceTrackerBasicUserAllCoursesScreen from "../../blocks/performancetracker/src/PerformanceTrackerBasicUserAllCoursesScreen";
import PerformanceTrackerStudentRankingScreen from "../../blocks/performancetracker/src/PerformanceTrackerStudentRankingScreen";
import PerformanceTrackerStudentInfoScreen from "../../blocks/performancetracker/src/PerformanceTrackerStudentInfoScreen";
import Analytics from "../../blocks/analytics/src/Analytics";
import Multilevelapproval2 from "../../blocks/multilevelapproval2/src/Multilevelapproval2";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";



const routeMap = {
Cfmcqs:{
 component:Cfmcqs,
path:"/Cfmcqs"},
Customisableuserprofiles2:{
 component:Customisableuserprofiles2,
path:"/Customisableuserprofiles2"},
Timetracker2:{
 component:Timetracker2,
path:"/Timetracker2"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Cfadmissionbatchmanagement:{
 component:Cfadmissionbatchmanagement,
path:"/Cfadmissionbatchmanagement"},
Cfcoursecreationbytrainerteacher:{
 component:Cfcoursecreationbytrainerteacher,
path:"/Cfcoursecreationbytrainerteacher"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Customform:{
 component:Customform,
path:"/Customform"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Documentstorage:{
 component:Documentstorage,
path:"/Documentstorage"},
Contentmanagement3:{
 component:Contentmanagement3,
path:"/Contentmanagement3"},
Contentflag:{
 component:Contentflag,
path:"/Contentflag"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Cfmerchantcentreonboarding:{
 component:Cfmerchantcentreonboarding,
path:"/Cfmerchantcentreonboarding"},
Blogpostsmanagement:{
 component:Blogpostsmanagement,
path:"/Blogpostsmanagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Cfcertification:{
 component:Cfcertification,
path:"/Cfcertification"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Communityforum3:{
 component:Communityforum3,
path:"/Communityforum3"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Addresses:{
 component:Addresses,
path:"/Addresses"},
AddAddress:{
 component:AddAddress,
path:"/AddAddress"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Scoring:{
 component:Scoring,
path:"/Scoring"},
Videos:{
 component:Videos,
path:"/Videos"},
Cfvideoconferencingtoolintegration:{
 component:Cfvideoconferencingtoolintegration,
path:"/Cfvideoconferencingtoolintegration"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Chat:{
 component:Chat,
path:"/Chat"},
ChatView:{
 component:ChatView,
path:"/ChatView"},
Videomanagement:{
 component:Videomanagement,
path:"/Videomanagement"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
PerformanceTracker:{
 component:PerformanceTracker,
path:"/PerformanceTracker"},
PerformanceTrackerCoursesScreen:{
 component:PerformanceTrackerCoursesScreen,
path:"/PerformanceTrackerCoursesScreen"},
PerformanceTrackerTestsForCourseScreen:{
 component:PerformanceTrackerTestsForCourseScreen,
path:"/PerformanceTrackerTestsForCourseScreen"},
PerformanceTrackerStudentsOnCourseScreen:{
 component:PerformanceTrackerStudentsOnCourseScreen,
path:"/PerformanceTrackerStudentsOnCourseScreen"},
PerformanceTrackerTestScoresForStudentScreen:{
 component:PerformanceTrackerTestScoresForStudentScreen,
path:"/PerformanceTrackerTestScoresForStudentScreen"},
PerformanceTrackerBasicUserCoursesScreen:{
 component:PerformanceTrackerBasicUserCoursesScreen,
path:"/PerformanceTrackerBasicUserCoursesScreen"},
PerformanceTrackerBasicUserAllCoursesScreen:{
 component:PerformanceTrackerBasicUserAllCoursesScreen,
path:"/PerformanceTrackerBasicUserAllCoursesScreen"},
PerformanceTrackerStudentRankingScreen:{
 component:PerformanceTrackerStudentRankingScreen,
path:"/PerformanceTrackerStudentRankingScreen"},
PerformanceTrackerStudentInfoScreen:{
 component:PerformanceTrackerStudentInfoScreen,
path:"/PerformanceTrackerStudentInfoScreen"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Multilevelapproval2:{
 component:Multilevelapproval2,
path:"/Multilevelapproval2"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},

  Home: {
component:HelpCentre,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
